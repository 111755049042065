._3PpwQiK0Fy_LVEZSnmAEyB {
  position: relative;
  display: inline-block;
  width: 132px; }

._1kr33vM1RHFoZEzw4DhYW4 {
  display: flex;
  height: 55px;
  width: 132px;
  justify-content: space-between;
  position: relative;
  border: 1px solid #BBBBBB; }

._3nsIo1J9lqSDM910ljin3k {
  align-self: center;
  cursor: pointer;
  margin-left: 8px;
  user-select: none;
  padding: 4px; }

._3nrjI_qt4CGn3dTNXrmFbS {
  color: #000000;
  align-self: center;
  text-shadow: 1px 0 #888888;
  font-weight: bolder; }

._2g26OwFFlpX_HYgLFO3ZXL {
  align-self: center;
  margin-right: 8px;
  padding: 4px;
  cursor: pointer;
  user-select: none;
  color: #9B9B9B; }

._3iyopEhJimFWsoLJ6NPiAD {
  border-right: 1px solid #BBBBBB;
  height: 100%;
  position: absolute;
  left: 30%;
  top: 0; }

._29w_BTRqqknh-mlxbkXNE9 {
  border-left: 1px solid #BBBBBB;
  height: 100%;
  position: absolute;
  right: 30%;
  top: 0; }
