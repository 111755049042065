._3xQkLkJS2VtnRuppK6oxs- {
  border: 1px solid lightgray;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100px;
  height: 100px;
  cursor: pointer; }
  ._3xQkLkJS2VtnRuppK6oxs-:first-of-type {
    margin-top: 0px; }
  ._3xQkLkJS2VtnRuppK6oxs-:last-of-type {
    margin-bottom: 0px; }

._29rI2YJO9_lgP6Jtz3IKOY {
  margin: 0 5px; }

._1vKye8qr9idBj7bojpaQR {
  background-size: contain;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center; }

._3xQkLkJS2VtnRuppK6oxs- img {
  align-self: center; }

._13B1y_jthpxnF8cZmQOOb_ {
  opacity: 0.6;
  transition: 0.5s opacity; }

._13B1y_jthpxnF8cZmQOOb_:hover {
  opacity: 1; }

.omneAq8V-6Ys8E1C9tBbN {
  object-fit: cover; }
  @media (max-width: 991px) {
    .omneAq8V-6Ys8E1C9tBbN {
      max-height: 200px; } }
  @media (min-width: 991px) {
    .omneAq8V-6Ys8E1C9tBbN {
      max-width: 100%;
      max-height: 30em; } }

._3SWXYy5P-6wbQislCJ6gcO {
  display: flex; }

._3F-NOK4aPizd0uUphQreBa {
  width: 100%;
  display: flex; }

.cMW1mhR0yA-EiX8BT9mas {
  width: 100%;
  display: flex;
  flex-direction: column-reverse; }

._323GBk1XCqaK3FIA9vo0Id i {
  align-self: center;
  width: 100%; }

._323GBk1XCqaK3FIA9vo0Id > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px; }

._3-NUOGTEuUpG18UUQe4ycB > div {
  flex-direction: row; }

._3g-ddkYOe12i4Zcyogl2Wm {
  flex: 4;
  align-content: center;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex; }
  @media (max-width: 768px) {
    ._3g-ddkYOe12i4Zcyogl2Wm {
      align-items: center; } }
  ._3g-ddkYOe12i4Zcyogl2Wm ._1Y1FQPqcjS39xDDZ9h-z7 {
    margin: 0; }
    @media (max-width: 991px) {
      ._3g-ddkYOe12i4Zcyogl2Wm ._1Y1FQPqcjS39xDDZ9h-z7 {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center; } }
  @media (max-width: 991px) {
    ._3g-ddkYOe12i4Zcyogl2Wm ._2dOv54raTWzAwIXDbZPvCi {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      padding: 0 4px; } }

.C_4OXsI4oN9DelH5dXGBZ {
  justify-content: flex-start !important;
  flex: auto !important; }
  .C_4OXsI4oN9DelH5dXGBZ .omneAq8V-6Ys8E1C9tBbN {
    max-width: 100%; }

._2uhojQCYkGF3j8q15mj3az:hover {
  color: gray; }

._2uhojQCYkGF3j8q15mj3az {
  font-size: 1.875rem;
  color: darkgray; }

._3Y-mb3botTDnd8kzYFVnTS {
  cursor: pointer; }

@media (max-width: 991px) {
  .tdaCQZ_ps_BgF0B3755g {
    margin-left: 0px !important; } }

@media (max-width: 991px) {
  ._2px-3B6kKyP-1y55Kuknrd {
    max-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center; } }

._18rFEVtthwY8UDcarFlMD4:before {
  content: "\F144";
  font-size: 1.25rem;
  text-align: center;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  line-height: 100px; }

._1pg8ekCBHYwAFfFM3GIcGc {
  opacity: 1;
  transition: opacity 0.5s linear; }

@keyframes _3ZR6LN1uwONTIbey03Qoc8 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

._3scqEXs_JKn0bvJASTdjNq {
  display: none; }

._335QkgO09JQMqaLB4-thS4 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  font-size: 35px;
  width: auto !important; }

._340V4WK3cTbQrxe96cVX0H {
  position: relative;
  height: 100%; }
