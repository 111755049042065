._3Sh-36TXrh2HQmdTyuuIGB {
  margin-bottom: 15px;
  margin-left: 15px; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._2iS8LPeT_kDRiyVR0MMYyM {
    margin-left: 10px;
    margin-right: 10px; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._3nIbC5JT_cIJF0yQlzAuAy {
    width: 100%; }
    ._3Sh-36TXrh2HQmdTyuuIGB ._3nIbC5JT_cIJF0yQlzAuAy td {
      padding: 8px;
      font-size: 0.95rem; }
    ._3Sh-36TXrh2HQmdTyuuIGB ._3nIbC5JT_cIJF0yQlzAuAy tr:nth-child(odd) {
      background: #eee; }
    ._3Sh-36TXrh2HQmdTyuuIGB ._3nIbC5JT_cIJF0yQlzAuAy tr:nth-child(even) {
      background: #fff; }
  ._3Sh-36TXrh2HQmdTyuuIGB .K9uu1HBryPMSuU3v2L2dy div {
    display: inline-block; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._22Qfz9MHQ7de5LY_2PF5lw {
    max-width: 250px;
    margin-top: 15px; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._2muLQC_GO_jqv7MkVJQgpA {
    max-width: 250px;
    margin-top: 15px; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._203dXPr58tR4hvPxsl0W3C {
    margin-top: 15px;
    margin-bottom: 15px; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._229W06pTKK5Zkal4bvX5gg {
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-weight: bold; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._2I0EW_ge5ZA8_-o1V8XIPw p,
  ._3Sh-36TXrh2HQmdTyuuIGB ._2I0EW_ge5ZA8_-o1V8XIPw h1,
  ._3Sh-36TXrh2HQmdTyuuIGB ._2I0EW_ge5ZA8_-o1V8XIPw h2,
  ._3Sh-36TXrh2HQmdTyuuIGB ._2I0EW_ge5ZA8_-o1V8XIPw h3,
  ._3Sh-36TXrh2HQmdTyuuIGB ._2I0EW_ge5ZA8_-o1V8XIPw h4,
  ._3Sh-36TXrh2HQmdTyuuIGB ._2I0EW_ge5ZA8_-o1V8XIPw h5 {
    margin-top: 15px; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._28qUAQAzqmsaOk_bSfonm2 {
    margin-bottom: 15px;
    margin-top: 5px; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._1Xa7tjRRROeXdQ3AV8ss97 {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    border: 1px solid #ccc;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: 0.5s background-color; }
    ._3Sh-36TXrh2HQmdTyuuIGB ._1Xa7tjRRROeXdQ3AV8ss97:hover, ._3Sh-36TXrh2HQmdTyuuIGB ._1Xa7tjRRROeXdQ3AV8ss97._2IPGjZzZhIcYQdp2ud3ePH {
      background-color: #1075b1;
      color: white;
      border: 1px solid white; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._1Vjg-_etAUpVvMQS24C7TS {
    display: inline-block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    cursor: pointer; }
    ._3Sh-36TXrh2HQmdTyuuIGB ._1Vjg-_etAUpVvMQS24C7TS:hover, ._3Sh-36TXrh2HQmdTyuuIGB ._1Vjg-_etAUpVvMQS24C7TS._2IPGjZzZhIcYQdp2ud3ePH {
      color: white;
      border: 1px solid; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._2RHD-UH__24DnLSHfywxmm {
    display: inline-block;
    border: 1px solid #ccc;
    margin-right: 5px;
    cursor: pointer; }
    ._3Sh-36TXrh2HQmdTyuuIGB ._2RHD-UH__24DnLSHfywxmm:hover, ._3Sh-36TXrh2HQmdTyuuIGB ._2RHD-UH__24DnLSHfywxmm._2IPGjZzZhIcYQdp2ud3ePH {
      border: 1px solid blue; }
    ._3Sh-36TXrh2HQmdTyuuIGB ._2RHD-UH__24DnLSHfywxmm ._3QU7X8i23-FI_jT8eRTy7J {
      max-width: 100px;
      background-size: contain;
      height: 75px;
      width: 75px;
      background-repeat: no-repeat;
      background-position: center; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._3PCVKj9B1KpM5zJJ2LTBKK {
    min-width: 200px; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._8k8rCEdHPu-gSuZfSXURq {
    margin-top: 15px; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._144Xv6qcf7PfHPmVm-P-eO {
    display: flex; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._3QWRsubxEUYhM14M0k7FP {
    width: 100%; }
    ._3Sh-36TXrh2HQmdTyuuIGB ._3QWRsubxEUYhM14M0k7FP ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._3izDt2sQcelsWLg7u7tQNO {
    margin-left: 10px; }
  ._3Sh-36TXrh2HQmdTyuuIGB ._22HvEDWp_bcD1U409DYdXQ {
    background-size: contain;
    background-repeat: no-repeat;
    width: 500px;
    height: 100%; }

.OQylGGsfNfHEIuPq2oL_0 ._25fGY8849PWxtAq1AUuPM3 {
  font-size: 0.85rem;
  margin-top: 5px; }

.OQylGGsfNfHEIuPq2oL_0 ._1dpJ4d9RVSdF09ZxT2xnnC {
  margin-top: 15px; }

.OQylGGsfNfHEIuPq2oL_0 p {
  font-size: 1.2rem; }

.OQylGGsfNfHEIuPq2oL_0 ._3UShAjuUIKAdSYr7O5An4p {
  font-size: 1.5rem; }

.OQylGGsfNfHEIuPq2oL_0 ._1dpJ4d9RVSdF09ZxT2xnnC {
  font-size: 1.4rem;
  font-weight: bold; }

._1qCMFNHiHJXM3AxGATyFXT {
  border: 1px solid #75afbe;
  color: #000;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 7px 5px 0;
  padding-left: 30px;
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  ._1qCMFNHiHJXM3AxGATyFXT i {
    color: #75afbe;
    position: absolute;
    top: 0;
    transform: translateY(70%);
    left: 25px;
    font-size: 25px; }
