._2Sumxas2b8KbT7xGWKAzHY {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px; }

._2XejcK6mtQCIf3TONv-Yl6 {
  text-transform: uppercase;
  font-size: 0.875rem; }

._8kJlPDPu7Tmb8rr2lpMC {
  flex: 1; }

._1HhfT5zyASFratqEHSOvyu {
  text-align: right;
  padding-right: 15px;
  font-weight: bold; }

.qO2fQMqThNeGZ4d55_BgB {
  margin-left: 15px; }
