.KvsSwE_Ybmo6LqoSyC5kD {
  line-height: 22px;
  margin-top: 15px; }

.pHsgJQ_6dEuNreM_QAbtR {
  color: #D0021B !important;
  font-size: 16px;
  font-weight: bold; }
  .pHsgJQ_6dEuNreM_QAbtR ._3nYGEOMiXYF7gL2HIdq5_M {
    color: #D0021B;
    font-size: 22px; }

._3wh8P86pyfBzOuFPAp56yG {
  font-size: 16px;
  font-weight: bold; }

._3nYGEOMiXYF7gL2HIdq5_M {
  font-size: 22px; }

._2iyk91B-vb0RnUqHbiH2GP {
  color: #8C8B8B !important;
  font-size: 16px;
  font-weight: 600; }

._3nlVeaWtbd8I-008f7yaVu {
  text-decoration: line-through;
  color: #8C8B8B !important;
  font-size: 16px; }
